import React from 'react';
import { Component, Fragment } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

export class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: this.props.currentPage,
            maxPage: this.props.maxPage,
            minPage: this.props.minPage,
            total: this.props.total,
            quizSection: this.props.quizSection,
            totalPages: this.props.maxPage - 1,
            lastSectionNumberBool: this.props.lastSectionNumberBool,
            lastSectionNumber: this.props.lastSectionNumber,
            quizPartenza: this.props.quizPartenza,
            nextArrowDisabled : this.props.nextArrowDisabled,
            pages: []
        }
    }

    componentDidMount() {
        let tmpPages = [];
        for (let i = 0; i <= this.state.total - 1; i++) {
            let pageObj = {
                pageNumber: i,
                checkQuiz: false
            }
            tmpPages.push(pageObj)
        }
        this.setState({
            pages: tmpPages,
        })

    }
    componentDidUpdate(propsPrecedenti) {
        if (this.props.currentPage !== propsPrecedenti.currentPage) {
            this.setState({
                currentPage: this.props.currentPage
            }, function () {
                let previousPage = this.state.currentPage - 1;
                let indexPage = this.state.pages.findIndex(pagina => pagina.pageNumber == previousPage)
                if (previousPage <= this.state.maxPage && previousPage >= this.state.minPage) {
                    let tmpPages = this.state.pages;
                    if (this.state.quizSection.includes(previousPage)) {
                        tmpPages[indexPage].checkQuiz = true;
                        this.setState({
                            pages: tmpPages
                        })
                    }
                }
            })
        }

        if (this.state.currentPage > this.state.maxPage){
            this.props.onNextClick(true); //Modifico nel parent il maxPage
        }

        if (this.props.maxPage !== propsPrecedenti.maxPage) {
            this.setState({
                maxPage: this.props.maxPage
            })
        }

        if (this.props.minPage !== propsPrecedenti.minPage) {
            this.setState({
                minPage: this.props.minPage
            })
        }

        if (this.props.total !== propsPrecedenti.total) {
            this.setState({
                total: this.props.total
            })
        }

        if (this.props.quizSection !== propsPrecedenti.quizSection) {
            this.setState({
                quizSection: this.props.quizSection
            })
        }

        if (this.props.lastSectionNumber !== propsPrecedenti.lastSectionNumber) {
            this.setState({
                lastSectionNumber: this.props.lastSectionNumber
            })
        }

        if (this.props.lastSectionNumberBool !== propsPrecedenti.lastSectionNumberBool) {
            this.setState({
                lastSectionNumberBool: this.props.lastSectionNumberBool
            })
        }

        if(this.props.nextArrowDisabled !== propsPrecedenti.nextArrowDisabled){
            this.setState({
                nextArrowDisabled: this.props.nextArrowDisabled
            })
        }
    }

    handlePrevClick = () => {
        this.props.onPrevClick();
    }
    handleNextClick = () => {
        this.props.onNextClick();
    }
    handlePageClick = (e) => {
        this.props.onPageChange(parseInt(e.target.id))
    }


    render() {
        return (
            <Box className='pagination' >
                {this.state.pages.length > 0 && (
                    <Fragment>
                        <IconButton onClick={this.handlePrevClick} disabled={this.state.currentPage === this.state.pages[0].pageNumber}>
                            {<ArrowBackIcon />}
                        </IconButton>
                        {this.state.pages.map((page, index) => (
                            <Fragment key={index}>
                                {page.pageNumber <= this.state.maxPage && page.pageNumber >= this.state.minPage && (
                                    index <  this.state.lastSectionNumber ?
                                (<Box id={page.pageNumber} onClick={(e) => this.handlePageClick(e)} className={`buttonPagination ${this.state.currentPage === page.pageNumber ? "active" : ''}
                                ${page.checkQuiz === true ? "checked" : ''}  ${this.state.currentPage === page.pageNumber && page.checkQuiz === true ? "checked-active" : ""}`}>
                                    {page.pageNumber + 1 +  this.state.quizPartenza}
                                </Box>)
                                :
                                (<Box id={page.pageNumber}
                                    className={`buttonPaginationDisabled`}>
                                    {page.pageNumber + 1 +  this.state.quizPartenza}
                                </Box>
                                )
                            )}
                            </Fragment>
                        ))}
                        <IconButton onClick={this.handleNextClick} disabled={(this.state.currentPage === this.state.pages[this.state.pages.length - 1].pageNumber) || (this.state.lastSectionNumberBool === true)}>
                            {< ArrowForwardIcon />}
                        </IconButton >
                    </Fragment>
                )}
            </Box >
        )
    }
}